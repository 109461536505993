<template>
    <div class="cyber-content">
        <el-scrollbar>
            <el-table class="customTable" :data="customList" style="width: 100%;min-height:140px;"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '60px'}">
                <el-table-column prop="inquiries" label="咨询量" align="center"></el-table-column>
                <el-table-column prop="accommodation_was" label="接待量" align="center"></el-table-column>
                <el-table-column prop="response_rate" label="应答率" align="center"></el-table-column>
                <el-table-column prop="response_rate_30" label="30s应答率" align="center"></el-table-column>
                <el-table-column prop="evaluation_rate" label="评价率" align="center"></el-table-column>
                <el-table-column prop="promoted_order_amount" label="促成下单金额" align="center"></el-table-column>
                <el-table-column prop="promoted_order_num" label="促成下单商品数" align="center"></el-table-column>
                <el-table-column prop="resolution_rate" label="解决率" align="center"></el-table-column>
            </el-table>
            <div class="middle-box">
                <el-tabs v-model="tabValue" class="middle-tabs" @tab-click="toggleTab">
                    <el-tab-pane label="咨询量" name="Inquiries"></el-tab-pane>
                    <el-tab-pane label="接待量" name="Accommodation"></el-tab-pane>
                    <el-tab-pane label="应答率" name="ResponseRate"></el-tab-pane>
                    <el-tab-pane label="30s应答率" name="ResponseRate30S"></el-tab-pane>
                    <el-tab-pane label="评价率" name="CommentRate"></el-tab-pane>
                    <el-tab-pane label="促成下单金额" name="OrderAmount"></el-tab-pane>
                    <el-tab-pane label="促成下单商品数" name="OrderProducts"></el-tab-pane>
                    <el-tab-pane label="解决率" name="ResolutionRate"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="bottom-box">
                <div id="monitorData" style="width: 100%;height: 100%;"></div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {drill_data_map, exam_data_map} from '@/utils/apis'

    export default {
        name: "realTimeMonitor",
        data() {
            return {
                customList: [],
                chartData: {},
                tabName: '咨询量',
                tabValue: 'Inquiries',
                monitorData: null,
                opId: Number(this.$route.query.id) || null,
                platform: this.$route.meta.type,
            }
        },
        mounted() {
            this.getData();
            this.$nextTick(() => {
                let resizeTimer = null;
                window.onresize = () => {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(() => {
                        this.monitorData.resize();
                    }, 100)
                }
            })
        },
        methods: {
            toggleTab(tab) {
                this.tabName = tab.label
                switch (Number(tab.index)) {
                    case 0:
                        this.getAreaData(this.chartData.inquiries.data);
                        break;
                    case 1:
                        this.getAreaData(this.chartData.accommodation_was.data);
                        break;
                    case 2:
                        this.getAreaData(this.chartData.response_rate.data);
                        break;
                    case 3:
                        this.getAreaData(this.chartData.response_rate_30.data);
                        break;
                    case 4:
                        this.getAreaData(this.chartData.evaluation_rate.data);
                        break;
                    case 5:
                        this.getAreaData(this.chartData.promoted_order_amount.data);
                        break;
                    case 6:
                        this.getAreaData(this.chartData.promoted_order_num.data);
                        break;
                    case 7:
                        this.getAreaData(this.chartData.resolution_rate.data);
                        break;
                }
            },
            getData() {
                this.customList = [];
                this.chartData = [];
                if (this.platform == 0) {
                    let params = {
                        op_id: this.opId,
                        module: 1
                    }
                    drill_data_map(params).then(res => {
                        let obj = {
                            inquiries: res.data.inquiries.value,
                            evaluation_rate: res.data.evaluation_rate.value,
                            accommodation_was: res.data.accommodation_was.value,
                            promoted_order_amount: res.data.promoted_order_amount.value,
                            promoted_order_num: res.data.promoted_order_num.value,
                            response_rate: res.data.response_rate.value,
                            resolution_rate: res.data.resolution_rate.value,
                            response_rate_30: res.data.response_rate_30.value,
                        }
                        this.customList.push(obj)
                        this.chartData = res.data
                        //默认显示咨询量
                        this.getAreaData(this.chartData.inquiries.data);
                    })
                } else {
                    let params = {
                        module: 1
                    }
                    exam_data_map(params).then(res => {
                        let obj = {
                            inquiries: res.data.inquiries.value,
                            evaluation_rate: res.data.evaluation_rate.value,
                            accommodation_was: res.data.accommodation_was.value,
                            promoted_order_amount: res.data.promoted_order_amount.value,
                            promoted_order_num: res.data.promoted_order_num.value,
                            response_rate: res.data.response_rate.value,
                            resolution_rate: res.data.resolution_rate.value,
                            response_rate_30: res.data.response_rate_30.value,
                        }
                        this.customList.push(obj)
                        this.chartData = res.data
                        //默认显示咨询量
                        this.getAreaData(this.chartData.inquiries.data);
                    })
                }
            },
            getAreaData(data) {
                let that = this;
                let dataX = [], dataY = [];
                data.forEach(item => {
                    dataX.push(item.name)
                    dataY.push(item.num)
                })
                this.monitorData = this.$echarts.init(document.getElementById('monitorData'));
                this.monitorData.setOption({
                    title: {
                        text: that.tabName,
                        textStyle: {
                            color: '#333333',
                            fontSize: 16
                        },
                        top: 48,
                        left: 56,
                    },
                    tooltip: {
                        borderWidth: "1", //边框宽度设置1
                        borderColor: "#888",
                        backgroundColor: '#fff',
                        textStyle: {
                            color: "#666666" //设置文字颜色
                        },
                        formatter: that.tabName + '<br />{b} : {c}',
                    },
                    grid: { //图表和父盒子之间的距离
                        left: 56,
                        right: '4%',
                        bottom: '3%',
                        top: 100,
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: dataX,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: function () {
                                return "";
                            }
                        },
                    },
                    series: [{
                        data: dataY,
                        type: 'line',
                        itemStyle: {
                            color: '#2DC079'
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 1,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#B2EFD4' // 0% 处的颜色
                                }, {
                                    offset: 1, color: '#A9EECF' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                    }]
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .cyber-content {
        margin: 20px;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
    }

    ::v-deep .el-scrollbar {
        height: 100%;

        .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }
    }

    .customTable {
        flex: unset;
    }

    .middle-box {
        width: 100%;
        position: relative;
        margin-top: 40px;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            top: 46px;
            height: 1px;
            background: #F1F5FF;
        }

        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }

    .middle-tabs {

        ::v-deep {
            .el-tabs__nav {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            .el-tabs__nav-wrap::after {
                content: none;
            }

            .el-tabs__header {
                margin-bottom: 0;
            }

            .el-tabs__active-bar {
                display: none;
            }

            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;

                &.is-active {
                    background: #E7F6EF;
                    color: #666666;
                }
            }

            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }

            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }

    .bottom-box {
        margin: 30px 0;
        flex: 1;
        box-shadow: 0px 0px 9px 0px rgba(124, 124, 124, 0.11);
        border-radius: 4px;
        border-left: 3px solid #FFAC75;
    }
</style>